import React, { useState, useEffect } from 'react';
import LogoColores from "../assets/LogoColores.png";
import AndroidDownload from "../assets/DownloadGoogle.png";
import AppleDownload from "../assets/DownloadApple.svg";

export default function Construction() {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
      )
    
      useEffect(() => {
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));
      }, []);
      console.log(matches)
  return (
    <div style={styles.container}>
      <span style={(matches)?styles.logoContainer:PhoneStyles.logoContainer}>
        <img alt="logo-fastpazi" src={LogoColores} style={(matches)?styles.logo:PhoneStyles.logo} />
        <p style={styles.lema}>"Revolutionazing Psoriasis Care"</p>
      </span>
      <span style={(matches)?styles.downloads:PhoneStyles.downloads}>
        <img
          alt="logo-Apple-Download"
          src={AppleDownload}
          style={(matches)?styles.apple:PhoneStyles.apple}
        />
        <img
          alt="logo-Android-Download"
          src={AndroidDownload}
          style={(matches)?styles.android:PhoneStyles.android}
        />
      </span>
      <footer style={styles.footer}>
        Contact Us
        <a style={styles.mailTo} href="mailto:support@fastpasi.com">
          support@fastpasi.com
        </a>
      </footer>
      {/* <a  style={styles.footer} name="body204">Contact Us: support@fastpasi.com</a> */}
      {/* <footer style={styles.footer}>Contact Us: support@fa stpasi.com</footer> */}
    </div>
  );
}

const styles = {
  footer: {
    textAlign: "center",
    display:'flex',
    flexFlow:'column wrap',
    // position: 'relative',
    // top: '-80px',
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  mailTo:{

  },
  container: {
    overflow: "hidden",
    height: "100vh",
  },
  downloads: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    transform: "scale(0.5)",
    position: "relative",
    top: "-50px",
    height: "20vh",
  },
  apple: {
    width: "30%",
    paddingLeft: "50px",
  },
  android: {
    width: "35%",
    paddingLeft: "47px",
  },
  logo: {
    width: "35vw",
  },
  logoContainer: {
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "center",
    justifyContent: "center",
    height: "70vh",
    width: "100%",
    position: "relative",
  },
  lema: {
    width: "100%",
    color: "rgb(19, 40, 51)",
    fontStyle: "italic",
    fontSize: "3.5vh",
    textAlign: "center",
    position: "relative",
    top: "-125px",
  },
  background: {
    height: "100vh",
    background:
      "linear-gradient(129deg, rgb(0% 28.235% 98.824%) 0%, rgb(0.077% 28.358% 98.82%) 6.25%, rgb(0.618% 29.198% 98.794%) 12.5%, rgb(2.086% 31.347% 98.723%) 18.75%, rgb(4.879% 35.092% 98.584%) 25%, rgb(8.218% 40.382% 98.356%) 31.25%, rgb(11.729% 46.972% 98.014%) 37.5%, rgb(15.39% 54.586% 97.533%) 43.75%, rgb(19.185% 62.996% 96.889%) 50%, rgb(22.301% 70.163% 96.239%) 56.25%, rgb(24.354% 74.98% 95.747%) 62.5%, rgb(25.711% 78.197% 95.392%) 68.75%, rgb(26.577% 80.26% 95.153%) 75%, rgb(27.086% 81.479% 95.008%) 81.25%, rgb(27.343% 82.095% 94.933%) 87.5%, rgb(27.438% 82.321% 94.906%) 93.75%, rgb(27.451% 82.353% 94.902%) 100% )",
  },
};
const PhoneStyles = {
    footer: {
      textAlign: "center",
      display:'flex',
      flexFlow:'column wrap',
      // position: 'relative',
      // top: '-80px',
      fontWeight: "bold",
      fontFamily: "Roboto",
    },
    mailTo:{
  
    },
    container: {
      overflow: "hidden",
      height: "100vh",
    },
    downloads: {
      display: "flex",
      flexFlow: "column wrap",
      justifyContent: "center",
      alignContent:"center",
    //   transform: "scale(0.5)",
      position: "relative",
      top: "-50px",
      height: "20vh",
    },
    apple: {
      width: "30%",
      paddingLeft: "3px",
    },
    android: {
      width: "35%",
    //   paddingLeft: "14px",
    },
    logo: {
    //   width: "100%",
    width:"75vw"
    },
    logoContainer: {
      display: "flex",
      flexFlow: "row wrap",
      alignContent: "center",
      justifyContent: "center",
      height: "70vh",
      width: "100%",
      position: "relative",
    },
    lema: {
      width: "100%",
      color: "rgb(19, 40, 51)",
      fontStyle: "italic",
      fontSize: "3.5vh",
      textAlign: "center",
      position: "relative",
      top: "-125px",
    },
    background: {
      height: "100vh",
      background:
        "linear-gradient(129deg, rgb(0% 28.235% 98.824%) 0%, rgb(0.077% 28.358% 98.82%) 6.25%, rgb(0.618% 29.198% 98.794%) 12.5%, rgb(2.086% 31.347% 98.723%) 18.75%, rgb(4.879% 35.092% 98.584%) 25%, rgb(8.218% 40.382% 98.356%) 31.25%, rgb(11.729% 46.972% 98.014%) 37.5%, rgb(15.39% 54.586% 97.533%) 43.75%, rgb(19.185% 62.996% 96.889%) 50%, rgb(22.301% 70.163% 96.239%) 56.25%, rgb(24.354% 74.98% 95.747%) 62.5%, rgb(25.711% 78.197% 95.392%) 68.75%, rgb(26.577% 80.26% 95.153%) 75%, rgb(27.086% 81.479% 95.008%) 81.25%, rgb(27.343% 82.095% 94.933%) 87.5%, rgb(27.438% 82.321% 94.906%) 93.75%, rgb(27.451% 82.353% 94.902%) 100% )",
    },
  };
  
