import Construction from './components/InConstruction'
function App() {
  return (
    <div className="App">
     <Construction/>
    </div>
  );
}

export default App;
